import React, { useEffect, useState } from 'react'

import SEO from '../../components/seo'
import Layout from '../../components/layout'
import HeaderIntroBlock from '../../components/Blocks/HeaderIntroBlock'
import SectionBoxReady from '../../components/Platfrom/SectionBoxReady'
import ImageTextBlock from '../../components/Blocks/ImageTextBlock'
import BreadcrumbSection from '../../components/breadcrumb'
import PopupSchedule from '../../components/popupSchedule/PopupSchedule'

const TargetAccounts = ({ location }) => {
     const canonical = process.env.GATSBY_SITE_URL + location.pathname
     const pageName = 'B2B Target Accounts'
     const breadcrumbItems = [
          {
               name: 'Platform',
               url: `${'platform'}`,
          },
          {
               name: `${pageName}`,
          },
     ]
     const headerIntroContent = {
          // subtitle: 'subtitle demo',
          title: 'Identify & Monitor Target Accounts on Your Website',
          // description: 'No more anonmyous data that leaves you wondering "what\'s really working?"...',
          substring: 'B2B marketers utilize ListenLayer to power their ABM analytics.',
     }
     const headerIntroCard = {
          description: `<span>Build your marketing data strategy around knowing who is actually browsing your website. </span>`,
          fName: 'Zachary Randall',
          fPosition: 'Founder & CEO, ListenLayer',
          imageURL: '/images/hero-image.png',
          imageAlt: 'Zachary Randall',
     }
     const headerIntroStep = {
          title: '',
          desciption: '',
     }
     const dataImageTextBlock = [
          {
               // iconSvg: '/images/Shape4x.png',
               title: 'ListenLayer Reveal',
               description:
                    'Learn more about how we identify and reveal real people and companies alongside your website traffic and analytics data.',
          },
          {
               // iconSvg: '/images/swap-arrows-duotone.svg',
               title: `It's <span class="underline-v2">Your Data</span> ... Own It!`,
               description:
                    'ListenLayer does not sell or use your data for our own benefit (unlike those "free" analytics tools). Your data belongs to you - which is why we\'re able to allow you to identify your own leads and customers!',
          },
          {
               // iconSvg: '/images/swap-arrows-duotone.svg',
               title: 'Security-first Architecture',
               description:
                    'We take the security of your data seriously, with full encryption, access controls, and proactive measures. ListenLayer is currently pursuing SOC2, Type 2 compliance to reflect our commitment to security.',
          },
          {
               // iconSvg: '/images/swap-arrows-duotone.svg',
               title: 'Privacy Compliance',
               description:
                    'How we identify users and store data enables compliance with ever-changing privacy laws. Our architecture supports GDPR, CCPA/CPRA, HIPAA, and other regulatory requirements, and ListenLayer can be used to completely replace (or augment) other Consent Managment Platforms.',
          },
     ]

     const [showSchedule, setShowSchedule] = useState(false)

     useEffect(() => {
          if (showSchedule) {
               document.querySelector('html').setAttribute('style', 'overflow:hidden')
          } else {
               document.querySelector('html').setAttribute('style', 'overflow:initial')
          }
     }, [showSchedule])

     useEffect(() => {
          if (window.location.href.includes('view-demo=1')) {
               setShowSchedule(true)
          }
     }, [])

     return (
          <Layout>
               <SEO canonical={canonical} />
               <PopupSchedule showSchedule={showSchedule} setShowSchedule={setShowSchedule} />
               <BreadcrumbSection breadcrumbItems={breadcrumbItems} />
               <HeaderIntroBlock headerIntroContent={headerIntroContent} headerIntroCard={headerIntroCard} headerIntroStep={headerIntroStep} />
               <section className="platform-step-card">
                    <div className="container">
                         <div className="box-card-step">
                              <div className="row">
                                   <div className="col-md-12 col-md-6 col-lg-4 mb-4">
                                        <div className="card-step">
                                             <div className="card-body">
                                                  <h3>Step 1:</h3>
                                                  <p className="card-text">Enable Reveal</p>
                                                  <p className="card-desc">
                                                       User ListenLayer to identify and reveal companies and people that are actively browsing your
                                                       website.
                                                  </p>
                                             </div>
                                        </div>
                                   </div>
                                   <div className="col-md-12 col-md-6 col-lg-4 mb-4">
                                        <div className="card-step">
                                             <div className="card-body">
                                                  <h3>Step 2:</h3>
                                                  <p className="card-text">Define Competitors</p>
                                                  <p className="card-desc">
                                                       Build multiple ABM target account lists based on company domain. Organize these lists in a
                                                       flexible manner based on your strategy.
                                                  </p>
                                             </div>
                                        </div>
                                   </div>
                                   <div className="col-md-12 col-md-6 col-lg-4 mb-4">
                                        <div className="card-step">
                                             <div className="card-body">
                                                  <h3>Step 3:</h3>
                                                  <p className="card-text">Learn & Analyze</p>
                                                  <p className="card-desc">
                                                       Monitor activity and learn about how your target accounts are engaging with your marketing
                                                       activity and browsing your website. Build a complete profile on each target account.
                                                  </p>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div className="text-desc mt-2">
                              <p>
                                   There is no better way to understand the effectiveness of your marketing than to reveal real people and companies
                                   within your data.
                              </p>
                              <p>
                                   Gain insights by developing lists of target accounts your marketing and sales team care about in your ABM marketing
                                   efforts.
                              </p>
                              <p>
                                   ListenLayer will build a profile for each company as they browse your website, connecting conversions, sessions,
                                   users, and people into a single view. You can even attach external data sources, such as Salesforce.com, to tie
                                   activity to pipeline and revenue.
                              </p>
                         </div>
                    </div>
               </section>
               <SectionBoxReady marginBottom={' mb-md-5'} marginTop={' mt-md-5'} />

               <section className="enhance-imgtext-block enhance-img-text">
                    <div className="container">
                         <ImageTextBlock dataImageTextBlock={dataImageTextBlock} />
                    </div>
               </section>
               <SectionBoxReady marginBottom={'mb-5 mb-md-5'} marginTop={'mt-2 mt-md-3'} />
          </Layout>
     )
}

export default TargetAccounts
